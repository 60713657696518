import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './menuNavbar.scss';
import konstruLogo from 'assets/images/konstru_logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { getLocalStorageItem } from 'services/others/storageServices';
import { useDispatch, useSelector } from 'react-redux';
import { loaderTrue } from 'redux/reducer/loaderReducer';
import {
  removeUserData,
  konstruAdminData,
  removeKonstruAdminData,
  removeAllKonstruUsersData,
  removeAllKonstruOrgsData
} from 'redux/reducer/userReducer';
import { addOrganizationsDetails } from 'redux/reducer/organizationsReducer';
import { getOrganizationsList } from 'services/api/organizationServices';
import {
  clearModelsDetails,
  clearRecentModelsDetails
} from 'redux/reducer/modelReducer';
import { GlobalContext } from 'constants/globalContext';
import { clearOrganizationsDetails } from 'redux/reducer/organizationsReducer';
import {
  clearMinimalModel,
  clearModelDxfExport,
  clearOrganizationCollaboratorsUpdated,
  clearRefreshModelsAndVersions,
  clearShowBaseModel,
  clearVersionMerged
} from 'redux/reducer/observerReducer';
import { resetAllSlices } from 'redux/store';
import { getKonstruAdmins } from 'services/api/userService';

function MenuNavbar() {
  const currentUserFromRedux = useSelector((state) => state?.user?.userData);
  const organizationsDetails = useSelector(
    (state) => state?.organizations?.organizationsDetails
  );
  const refreshOrgAndAdminDetails = useSelector(
    (state) => state?.organizations?.refreshOrgAndAdminDetails
  );
  const konstruAdminList = useSelector((state) => state?.user?.konstruAdmins);
  const [orgList, setOrgList] = useState([]);
  const { socket } = useContext(GlobalContext);
  const [currentUser, setCurrentUser] = useState(
    Object.keys(currentUserFromRedux).length > 0
      ? currentUserFromRedux
      : getLocalStorageItem('currentUser')
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function _closeUserRoom(userId) {
    if (socket) {
      socket.emit('logout', userId);
    }
  }

  const getOrganizationsDetails = async () => {
    if (organizationsDetails?.length === 0 || refreshOrgAndAdminDetails) {
      let response = await getOrganizationsList();
      dispatch(addOrganizationsDetails(response));
    }
  };

  const getKonstruAdminDetails = async () => {
    if (konstruAdminList?.length === 0 || refreshOrgAndAdminDetails) {
      let kAdmins = await getKonstruAdmins();
      if (kAdmins?.data) dispatch(konstruAdminData(kAdmins.data));
    }
  };

  useEffect(() => {
    if (currentUser) {
      getOrganizationsDetails();
      getKonstruAdminDetails();
    }
  }, [refreshOrgAndAdminDetails]);

  const onHandleLogout = () => {
    dispatch(clearOrganizationsDetails());
    _closeUserRoom(currentUser?._id);
    dispatch(loaderTrue());
    localStorage.clear();
    dispatch(removeUserData());
    dispatch(clearRecentModelsDetails());
    dispatch(clearModelsDetails());
    dispatch(clearRefreshModelsAndVersions());
    dispatch(clearMinimalModel());
    dispatch(clearVersionMerged());
    dispatch(clearShowBaseModel());
    dispatch(clearOrganizationCollaboratorsUpdated());
    dispatch(clearModelDxfExport());
    dispatch(removeKonstruAdminData());
    dispatch(removeAllKonstruUsersData());
    dispatch(removeAllKonstruOrgsData());
    navigate('/');
  };

  const handleRedirect = () => {
    // dispatch(loaderTrue());
    navigate('/');
  };

  useEffect(() => {
    if (getLocalStorageItem('currentUser')) {
      setCurrentUser(getLocalStorageItem('currentUser'));
    }
  }, [localStorage.getItem('currentUser')]);

  useEffect(() => {
    const currUser = getLocalStorageItem('currentUser') || {};
    if (!Object.prototype.hasOwnProperty.call(currUser, '_id')) return;
    const filteredOrgsOwner = organizationsDetails.filter(
      (elem) => elem?.owner?._id === currUser?._id
    );
    const filteredOrgs = organizationsDetails.filter(
      (elem) => elem?.owner?._id !== currUser?._id
    );
    const sortedOrgList = [...filteredOrgs].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setOrgList([...filteredOrgsOwner, ...sortedOrgList]);
  }, [organizationsDetails]);
  return (
    <>
      <Navbar expand="md" className="bg-nav-body-darkgreen menu-nav">
        <Container fluid>
          <Navbar.Brand onClick={handleRedirect}>
            <img
              src={konstruLogo}
              className="img-fluid img-cursur"
              width={'auto'}
              height={'auto'}
              alt="konstru logo"
            />
          </Navbar.Brand>
          {currentUser && currentUser?.firstName && (
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          )}
          {currentUser && currentUser?.firstName && (
            <Navbar.Collapse id="basic-navbar-nav ">
              <Nav className="me-auto">
                <Nav.Link onClick={() => navigate('/dashboard')} className="">
                  Dashboard
                </Nav.Link>
                <Nav.Link onClick={() => navigate('/models')} className="">
                  Models
                </Nav.Link>
                <NavDropdown
                  title="Documentation"
                  id="navbarScrollingDropdown"
                  className="header-custom-dropdown onetwo"
                >
                  <NavDropdown.Item
                    onClick={() => {
                      window.open(
                        'https://konstru.gitbook.io/konstru-manual/getting-started',
                        '_blank'
                      );
                    }}
                  >
                    Getting Started
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      window.open(
                        'https://konstru.gitbook.io/konstru-manual/konstru-supported-element-types/datums-and-groups',
                        '_blank'
                      );
                    }}
                  >
                    Supported Elements
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      window.open(
                        "https://konstru.zendesk.com/hc/en-us/categories/115000673147-Tutorials%7B%7B'-",
                        '_blank'
                      );
                    }}
                  >
                    Tutorials
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      window.open(
                        'https://konstru.zendesk.com/hc/en-us/community/topics/115000107608-General-Discussion',
                        '_blank'
                      );
                    }}
                  >
                    User Forum
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      window.open(
                        'https://www.youtube.com/channel/UCaLKLwA-u2xzJLFj7ShAy9Q/videos',
                        '_blank'
                      );
                    }}
                  >
                    Youtube Channel
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      window.open(
                        'https://konstru.gitbook.io/konstru-manual/konstru-faq',
                        '_blank'
                      );
                    }}
                  >
                    FAQ
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  onClick={() => navigate('/downloadPlugins')}
                  className=""
                >
                  Get Plugins
                </Nav.Link>
                <Nav.Link onClick={() => navigate('/bugReport')} className="">
                  Help
                </Nav.Link>
                {konstruAdminList &&
                  currentUser &&
                  konstruAdminList.includes(currentUser?._id) && (
                    <Nav.Link
                      onClick={() => navigate('/usageAnalytics')}
                      className=""
                    >
                      Stats
                    </Nav.Link>
                  )}
              </Nav>
              <NavDropdown
                title={
                  currentUser?.avatarUrl ? (
                    <div className="d-flex align-items-center">
                      <img
                        width={24}
                        src={currentUser?.avatarUrl}
                        alt={currentUser?.initials}
                        className="rounded-2"
                      />
                    </div>
                  ) : (
                    currentUser?.initials
                  )
                }
                id="basic-nav-dropdown"
                className="header-user-dropdown rounded-2"
              >
                <NavDropdown.Item onClick={() => navigate('/profile')}>
                  Your Profile
                </NavDropdown.Item>

                {konstruAdminList.length > 0 && (
                  <NavDropdown.Item onClick={() => navigate('/admin')}>
                    Admin
                  </NavDropdown.Item>
                )}

                <NavDropdown.Divider />
                <div className="menuNavbar-organization-list">
                  {orgList &&
                    orgList?.length > 0 &&
                    orgList?.map((list) => {
                      return (
                        <NavDropdown.Item
                          key={list?._id}
                          onClick={() => {
                            navigate(`/organizations/${list?._id}/models`);
                          }}
                        >
                          {list?.name}
                        </NavDropdown.Item>
                      );
                    })}
                </div>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={onHandleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
              {/* )} */}
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
    </>
  );
}

export default MenuNavbar;
