export const endpoint = {
  getProfile: '/api/users',
  updateProfile: '/api/users',
  uploadFile: '/api/v2/uploads',
  contactUs: '/api/v2/requests',
  login: '/auth/login',
  verify: '/auth/verify',
  signUp: '/auth/signup',
  forgotPassword: '/auth/forgot',
  resetPassword: '/auth/reset',
  uploadAvatar: '/api/images/profileImage',
  removeAvatar: '/api/images/users',
  getOrganizationsList: '/api/organizations',
  getActiveModels: '/api/models',
  getImagesAndVersionOfModels: '/api/models/imagesAndVersionsByModelIds',
  getModelsVersionImages: '/api/images/getBatchImageUrls',
  // "organizations/640ee5a46404f60002ea6289/collaborators/649961f9f0d4d400023e7dad"
  organizationsCollaborators: '/api/organizations',
  getOrgColoborator: '/api/users/findByEmail',
  createANewModel: '/api/models/',
  updateOrganizationsName: '/api/organizations/',
  getUserByEmail: '/api/users/findByEmail',
  inviteUser: '/api/organizations', ///api/organizations/64e33d99cb8b7ea3303da169/collaborators
  deleteColloborator: '/api/organizations', //api/organizations/640ee5a46404f60002ea6289/collaborators/64ec39382765e4d4c160f37a
  // https://app.konstru.com/api/organizations/640ee5a46404f60002ea6289/filters?_ANALYTICS=browser:Chrome_116.0.0.0,device:undefined_undefined,os:Linux_x86_64&fromWebUI=true&konstruSessionId=1693202361504
  getFilterList: '/api/organizations',
  deleteFilter: '/api/organizations', //api/organizations/640ee5a46404f60002ea6289/filters/64ec9a9a72b881f69107c9d2
  createNewFilter: '/api/organizations/', //api/organizations/640ee5a46404f60002ea6289/filters
  updateOrganizationsBilling: '/api/organizations',
  updateFilter: '/api/organizations', //api/organizations/640ee5a46404f60002ea6289/filters/64ede8f4e75d2692d9138f8b
  adminOrganizationList: '/api/organizations/all',
  allUsersList: '/api/users/adminList',
  fuzzySerachForUser: '/api/users/fuzzySearch/',
  updateTestingGroup: '/api/users/updateTestingGroup/',
  getProfileSettingData: '/api/users/profileInfo',
  getKonstruAdmins: '/api/users/konstruAdmins',
  getUser: '/api/users/',

  //////////////STRIPE//////////////////
  config: '/stripe/config',
  // API: https://konstru-amc-dev.herokuapp.com/api/organizations/6504010e90bfbe49bc4ae131/payments/setupIntents
  stripeSetupIntent: '/api/organizations',
  // API : https://konstru-amc-dev.herokuapp.com/api/organizations/6504010e90bfbe49bc4ae131/payments/setup
  stripeSetup: '/api/organizations',
  //API : https://konstru-amc-dev.herokuapp.com/api/organizations/6504010e90bfbe49bc4ae131/payments/sources
  stripeACHSources: '/api/organizations',
  // https://konstru-amc-dev.herokuapp.com/api/organizations/6504010e90bfbe49bc4ae131
  changeBillingCycle: '/api/organizations',
  // https://konstru-amc-dev.herokuapp.com/api/organizations/6504010e90bfbe49bc4ae131?_ANALYTICS=browser:Chrome_116.0.0.0,device:undefined_undefined,os:Linux_x86_64&konstruSessionId=1695881668480
  getSingleOrg: '/api/organizations',
  // https://konstru-amc-dev.herokuapp.com/api/organizations/6504010e90bfbe49bc4ae131/payments?_ANALYTICS=browser:Chrome_116.0.0.0,device:undefined_undefined,os:Linux_x86_64&konstruSessionId=1695897275628
  removePaymentMethod: '/api/organizations/',
  // https://konstru-amc-dev.herokuapp.com/api/organizations/6504010e90bfbe49bc4ae131/payments/verifySources
  verifyPaymentMethod: '/api/organizations',
  // https://konstru-amc-dev.herokuapp.com/api/models/64d272f084025dcaa0cf49fd/collaborators/64f9adb19d6774c43bc68175
  modelColloborater: '/api/models',
  //  temparrary comment
  // https://konstru-amc-dev.herokuapp.com/api/models/64d272f084025dcaa0cf49fd/clone
  uploadModelScreenShot: '/api/images/modelScreenshot'
};
