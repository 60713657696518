import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  models: [],
  recentActivityModels: [],
  cloneModel: [],
  refreshModels: false
};

export const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    addModelsDetails: (state, action) => {
      if (action?.payload?.length > 0) {
        state.models = [...action.payload];
      }
    },
    addRecentModelsDetails: (state, action) => {
      if (action?.payload?.length > 0) {
        state.recentActivityModels = [...action.payload];
      }
    },
    cloneModelNew: (state, action) => {
      if (action?.payload?.length > 0) {
        state.cloneModel = [...action.payload];
      }
    },
    clearCloneModelNew: (state, action) => {
      state.cloneModel = [];
    },

    // updateModelsDetails: (state, action) =>{
    //     state.models =
    // },
    clearRecentModelsDetails: (state) => {
      state.models = [];
    },
    clearModelsDetails: (state) => {
      state.recentActivityModels = [];
    },
    triggerRefreshModels: (state, action) => {
      state.refreshModels = action.payload;
    }
  }
});

export const {
  addModelsDetails,
  clearModelsDetails,
  addRecentModelsDetails,
  clearRecentModelsDetails,
  cloneModelNew,
  clearCloneModelNew,
  triggerRefreshModels
} = modelsSlice.actions;

export default modelsSlice.reducer;
